<template>
    <div class="dx-doc-viewer" ref="docRef" :style="docStyle"></div>
</template>
<script setup lang="ts">
import { renderHighlight } from '@/commons/utils';
import * as docx from 'docx-preview';
import { defineEmits, defineProps, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps<{
    src: string;
    highlightText?: any;
    page?: number;
    isPreview?: boolean;
}>()
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);
const docRef = ref();
const docStyle = ref<any>({
    '--doc-scale': 1,
    opacity: 0,
});

watch(() => [props.src],
() => {
    if(props.src) {
        loadDoc();
    }
}, {
    immediate: true
})

watch(() => [props.highlightText],
() => {
    if(props.src) {
      renderHighlight({
          container: docRef.value,
          divs: Array.from(docRef.value?.querySelectorAll('.docx span')?? []),
          highlightText: props.highlightText,
          pageClass: ".docx-wrapper .docx",
          page: props.page
        });    }
})

let originDocRect: any;
onMounted(() => {
  window.addEventListener("dxResize", calculateSize);
  window.addEventListener("resize", calculateSize);
})
onUnmounted(() => {
  window.removeEventListener("dxResize", calculateSize);
  window.removeEventListener("resize", calculateSize);
})
function calculateSize() {
  const docContainerRect = docRef.value?.getBoundingClientRect();
  if(docContainerRect) {
    docStyle.value["--doc-scale"] =  docContainerRect?.width / originDocRect?.width;
    docStyle.value['--doc-height'] = originDocRect?.height * docStyle.value["--doc-scale"] + 'px';
    docStyle.value["opacity"] = 1;
  }
}

function loadDoc() {
    emits("onload");
    docRef.value?.scrollIntoView();
    docStyle.value["--doc-scale"] = 1;
    docStyle.value["opacity"] = 0;
    fetch(props.src)
    .then(res => res.blob())
    .then(blob => docx.renderAsync(blob, docRef.value, undefined, {inWrapper: true, useBase64URL: true, ignoreLastRenderedPageBreak: false}))
    .then(() => {
        const docRect = docRef.value.querySelector(".docx").getBoundingClientRect();
        const containerRect = docRef.value.getBoundingClientRect();
        originDocRect = {
          width: docRect.width,
          height: containerRect.height
        };
        calculateSize();
        renderHighlight({
          container: docRef.value,
          divs: Array.from(docRef.value?.querySelectorAll('.docx span')?? []),
          highlightText: props.highlightText,
          pageClass: ".docx-wrapper .docx",
          page: props.page
        });

        if(props.isPreview) {
          renderFirstPage();
        }
        emits("onloadDone");
    })
    .catch(err => {
        emits("onloadFail", err);
    })
    .finally(() => {
      //
    });
}

function renderFirstPage() {
  docRef.value.querySelectorAll(".docx").forEach((page: any, index: number) => {
    if (index !== 0) { 
      page.remove();
    }
  });
}

</script>
<style lang="scss" scoped>
.dx-doc-viewer {
  height: var(--doc-height);
  background-color: white;
  :deep(.docx-wrapper) {
      width: fit-content;
      padding: 0;
      transform-origin: 0 0;
      transform: scale(var(--doc-scale));
      .docx {
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 1px;
        }
        box-shadow: none;
        span.highlight {
          background-color: #ffff0020;
        }
      }
  }
}
</style>