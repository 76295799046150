<template>
    <div ref="container" class="dx-json-viewer" :class="{'is-preview': isPreview}">
        <pre>{{ data }}</pre>
    </div>
</template>
<script setup lang="ts">
import { renderHighlight as utilRenderHighlight } from '@/commons/utils';
import { defineEmits, defineProps, ref, watch } from 'vue';

const props = defineProps<{
    src: string;
    highlightText?: any;
    isPreview?: boolean;
}>()
const container = ref();
const data = ref();
watch(() => [props.src],
() => {
    if(props.src) {
        load();
    }
}, {
    immediate: true
})

watch(() => [props.highlightText],
() => {
    if(props.src) {
        renderHighlight();
    }
})
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);

function load() {
    fetch(props.src)
    .then((response) => {
       return response.json();
    })
    .then(json => {
        data.value = json;
        setTimeout(() => {
            renderHighlight();
            emits("onloadDone");
        }, 0);
    })
    .catch(err => {
        emits("onloadFail", err);
    })
}
function renderHighlight() {
    if(data.value && props.highlightText && container.value) {
        const regexStr = props.highlightText
                            .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                            .replace(/\s+/g, '\\s*')
                            .replace(/([{}[\]:,])/g, '$&\\s*')
                            .replace(/\\}/g, '\\s*\\}')
                            .replace(/\\]/g, '\\s*\\]')
                            .replace(/,/g, '\\s*,')
        const regex = new RegExp(regexStr, 'gi');
        utilRenderHighlight({
          container: container.value,
          divs: [container.value.querySelector('pre')],
          highlightText: props.highlightText,
          customRegex: regex
        })
    }
}
</script>
<style lang="scss" scoped>
.dx-json-viewer {
    width: 100%;
    text-align: left;
    &.is-preview {
        font-size: 3px;
    }
    padding: 1em;
    pre {
        margin: 0;
        white-space: pre-wrap;
    }
    :deep(span.highlight) {
        background-color: #ffff0020;
    }
}
</style>