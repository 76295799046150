<template>
    <div class="dx-file-viewer" :class="{'is-preview': isPreview}">
        <div v-if="file.type == FileType.PDF" class="dx-file-viewer__pdf">
            <DxPdfViewer
                :src="(file as File).fileUrl"
                :is-preview="isPreview"
                :highlight-text="highlightText"
                :page="page"
                @onload="loading = true"
                @onload-done="onLoaded"
                @onload-fail="onLoadFail()"/>
        </div>
        <div v-else-if="file.type == FileType.DOCX" class="dx-file-viewer__doc">
            <DxDocViewer
                :src="(file as File).fileUrl"
                :is-preview="isPreview"
                :highlight-text="highlightText"
                :page="page"
                @onload="loading = true"
                @onload-done="onLoaded"
                @onload-fail="onLoadFail()"/>
        </div>
        <img v-else-if="file.type == FileType.IMAGE" class="dx-file-viewer__image" :src="(file as File).fileUrl"/>
        <div v-else-if="file.type == FileType.VIDEO" class="dx-file-viewer__video">
            <video controls>
                <source :src="getImageUrl(file)" >
                Your browser does not support the video tag.
            </video>
        </div>
        <div v-else-if="file.type == FileType.SOUND" class="dx-file-viewer__sound">
            <audio controls>
                <source :src="getImageUrl(file)" >
                Your browser does not support the audio tag.
            </audio>
        </div>
        <div v-else-if="file.type == FileType.XLSX || file.type == FileType.UNKNOW && fileExt == 'csv'" class="dx-file-viewer__xlsx">
            <DxSpreedsheetViewer 
                :src="(file as File).fileUrl"
                :is-preview="isPreview"
                :highlight-text="highlightText"
                @onload="loading = true"
                @onload-done="onLoaded"
                @onload-fail="onLoadFail()" />
        </div>
        <div v-else-if="file.type == FileType.TXT" class="dx-file-viewer__txt">
              <DxPlainTextViewer v-if="fileData" :text="fileData" :highlight-text="highlightText" :is-preview="isPreview"></DxPlainTextViewer>
        </div>
        <div v-else-if="file.type == FileType.UNKNOW" class="dx-file-viewer__unknow">
            <DxJsonViewer
                            v-if="fileExt == 'json'"
                            :src="(file as File).fileUrl"
                            :highlight-text="highlightText"
                            :is-preview="isPreview"
                            @onload-done="onLoaded"/>
            <DxMarkdownViewer 
                            v-else-if="fileExt == 'md'"
                            :src="(file as File).fileUrl"
                            :highlight-text="highlightText"
                            :is-preview="isPreview" 
                            @onload-done="onLoaded"/>
            <DxYamlViewer 
                            v-else-if="fileExt == 'yml' || fileExt == 'yaml'"
                            :src="(file as File).fileUrl"
                            :highlight-text="highlightText"
                            :is-preview="isPreview" 
                            @onload-done="onLoaded"/>
            <div v-else class="dx-file-unknow">
                <div class="dx-file-unknow__img">
                    <inline-svg :src="require(`@/assets/imgs/icons/file-type-${file.type}.svg`)"/>
                </div>
                <div class="dx-file-unknow__name">
                    {{ file.name }}
                </div>
                <div class="dx-file-unknow__modified">
                    {{ file.date }}
                </div>
                <div class="dx-file-unknow__size">
                    {{ (file as File).sizeFormat }}
                </div>
            </div>
        </div>
        <DxSpinner v-if="loading && !isPreview" class="dx-file-viewer__loading" />
        <div v-if="loading && !isPreview" class="dx-file-viewer__loading-img">
            <img src="@/assets/imgs/filesearch/large-file-warning.svg"/>
            <p v-html="
                    $t(`Large files may take some time to preview.<br/> 
                        Please be patient while we process the file.`)">
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
import { getImageUrl } from '@/commons/utils';
import { useToast } from '@/composables/useToast';
import { File, FileType, IFile } from '@/models/file';
import { defineProps,defineEmits, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import DxSpinner from './DxSpinner.vue';
import DxDocViewer from './file-viewer/DxDocViewer.vue';
import DxSpreedsheetViewer from './file-viewer/DxSpreedsheetViewer.vue';
import DxPdfViewer from './file-viewer/DxPdfViewer.vue';
import DxPlainTextViewer from './file-viewer/DxPlainTextViewer.vue';
import DxJsonViewer from './file-viewer/DxJsonViewer.vue';
import DxMarkdownViewer from './file-viewer/DxMarkdownViewer.vue';
import DxYamlViewer from './file-viewer/DxYamlViewer.vue';

const { t } = useI18n();
const props = defineProps<{
    file: IFile,
    highlightText?: any;
    page?: number;
    isPreview? : boolean;
}>()

const emits = defineEmits(["onLoaded", "onLoadFail"]);

const loading = ref(false);

const fileExt = ref("");

const fileData = ref();

watch(() => props.file,
() => {
    if(props.file) {
        init();
    }
}, {
    immediate: true
})
function init() {
    fileExt.value = props.file.name?.split('.')?.pop()?.toLowerCase()??"";
    fileData.value = '';
    switch (props.file.type) {
        case FileType.IMAGE:
        case FileType.SOUND:
        case FileType.VIDEO:
            onLoaded();
            break;
        case FileType.TXT:
            if(fileExt.value =='csv') return;
            loading.value = true;
            fetch((props.file as File).fileUrl)
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                fileData.value = data;
                if(props.isPreview && props.file.type != FileType.TXT) {
                    return;
                }
                onLoaded();
            })
            .catch((err) => {
                onLoadFail();
            })
            .finally(() => {
                //
            });
            break
        default:
            break;
    }
}

function onLoaded() {
    emits("onLoaded");
    loading.value = false;
}
function onLoadFail() {
    emits("onLoadFail");
    if(!props.isPreview) {
        useToast({message: t("errorMessage.readingFile"), type: 'error'});
    }
}

</script>
<style lang="scss" scoped>
.dx-file-viewer {
    position: relative;
    width: 100%;
    height: 100%;
    &.is-preview > * {   
        overflow: hidden;
        cursor: default;
    }
    &__pdf, &__doc {
        display: block;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &__txt {
        white-space: break-spaces;
        height: 100%;
    }
    &__xlsx {
        width: 100%;
        height: 100%;
    }
    &__video, &__sound {
        width: 100%;
        height: 100%;
        display: flex;
        video {
            width: 100%;
            height: auto;
            max-height: 100%;
            object-fit: contain;
            margin: auto;
        }
        audio {
            margin: auto;
        }
    }
    &__unknow {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: white;
        .dx-file-unknow {
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            height: 425px;
            max-width: 80%;
            max-height: 80%;
            @media only screen and (max-width: 768px) {
                gap: 5px;
            }
            &__img {
                flex: 1;
                min-height: 0;
                height: 100%;
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
    &__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      color: rgba($color1, 0.1);
      z-index: 4;
      translate: -50% -50%;
      animation: fadeOut 0s ease-in-out 3s forwards;
      opacity: 1;
    }
    &__loading-img {
      position: absolute;
      width: 60%;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-items: center;
      animation: fadeIn 0s ease-in-out 3s forwards;
      opacity: 0;
      img {
        width: 60%;
      }
      p {
        text-align: center;
        color: rgba($color1, 0.4);
      }
    }

    
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
    
    
    @keyframes fadeOut {
        to {
            opacity: 0;
        }
    }
    
}
</style>