<template>
    <div ref="containter" class="dx-yaml-viewer" :class="{'is-preview': isPreview}">
        <pre>{{ data }}</pre>
    </div>
</template>
<script setup lang="ts">
import { renderHighlight } from '@/commons/utils';
import { defineEmits, defineProps, ref, watch } from 'vue';

const props = defineProps<{
    src: string;
    highlightText?: any;
    isPreview?: boolean;
}>()
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);
const containter = ref();
const data = ref();
watch(() => [props.src],
() => {
    if(props.src) {
        load();
    }
}, {
    immediate: true
})

watch(() => [ props.highlightText],
() => {
    if(props.src) {
        renderHighlight({
          container: containter.value,
          divs: [containter.value.querySelector('pre')],
          highlightText: props.highlightText
        });
    }
})
 
function load() {
    fetch(props.src)
    .then((response) => {
        return response.text();
    })
    .then(text => {
        data.value = text;
        setTimeout(() => {
            renderHighlight({
              container: containter.value,
              divs: [containter.value.querySelector('pre')],
              highlightText: props.highlightText
            });
            emits("onloadDone");
        }, 0);
    })
}

</script>
<style lang="scss" scoped>
.dx-yaml-viewer {
    width: 100%;
    padding: 1em;
    text-align: left;
    &.is-preview {
        font-size: 3px;
    }
    pre {
        margin: 0;
    }
    :deep(span.highlight) {
        background-color: #ffff0020;
    }
}
</style>