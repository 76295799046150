<template>
    <div ref="containter" class="dx-plaintext-viewer" :class="{'is-preview': isPreview}">
        {{ text }}
    </div>
</template>
<script setup lang="ts">
import { renderHighlight } from '@/commons/utils';
import { defineProps, ref, watch } from 'vue';

const containter = ref();

const props = defineProps<{
  text: string;
  highlightText?: any;
  isPreview?: boolean
}>();

watch(() => [props.text, props.highlightText, containter.value],
() => {
  renderHighlight({
    container: containter.value,
    divs: [containter.value],
    highlightText: props.highlightText
  });
},
{immediate: true})

</script>
<style lang="scss" scoped>
.dx-plaintext-viewer {
  white-space: break-spaces;
  word-wrap: break-word;
  font-family: inherit;
  width: 100%;
  height: 100%;
  padding: 1em;
  background-color: white;
  text-align: left;
  overflow: auto;
  &.is-preview {
    font-size: 3px;
    overflow: hidden;
  }
  :deep(span.highlight) {
      background-color: #ffff0020;
  }
}
</style>