<template>
    <div class="dx-spreedsheet-viewer" ref="elRef" :class="{'is-preview': isPreview}">
      <div class="dx-spreedsheet-viewer__sheet" v-for="sheet in sheets" :key="sheet.index">
        <div>{{ sheet.sheetName }}</div>
        <div v-html="sheet.data"></div>
      </div>
    </div>
</template>
<script setup lang="ts">
import { defineEmits, defineProps, ref, watch } from 'vue';
import * as XLSX from 'xlsx';
const props = defineProps<{
    src: string;
    highlightText?: any;
    isPreview?: boolean;
}>()
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);
const elRef = ref();
const html = ref("");
const sheets = ref<any>([]);
watch(() => [props.src],
() => {
    if(props.src) {
        render();
    }
}, {
    immediate: true
})

async function render() {
  try {
    const buffer = await fetch(props.src)
    .then(res => res.arrayBuffer())

    const ab = new Uint8Array(buffer).buffer;
    const wb = XLSX.read(ab, { type: "array" });
    const sheetNames = props.isPreview && wb.SheetNames.length > 0 ? [wb.SheetNames[0]] : wb.SheetNames;
    sheets.value = sheetNames.map((sheetName: string, index: number) => {
      return { 
              key: index,
              sheetName: sheetName,
              data: XLSX.utils.sheet_to_html(wb.Sheets[wb.SheetNames[index]])
            };
    })
    html.value = XLSX.utils.sheet_to_html(wb.Sheets[wb.SheetNames[0]]);
    emits("onloadDone")
  } catch(err) {
    emits("onloadFail", err);
  }
}

</script>
<style lang="scss" scoped>
.dx-spreedsheet-viewer {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: white;
  font-size: 13px;
  &.is-preview {
    overflow: hidden;
    font-size: 3px;
    cursor: default;
    pointer-events: none;
    :deep(*) {
      user-select: none;
    }
  }

  &__sheet {
    margin-top: 1em;
    padding: 1em;
    width: fit-content;
    min-width: 100%;
  }

  $table-border-color: #d0d7de;
  $table-header-background: #e9ebf3;
  $table-cell-padding: 0.5em;
  $table-header-font-weight: bold;
  $table-row-hover-background: #dfe3ee;

  :deep(table) {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;
    margin-bottom: 2em;
    
    th, td {
      border: 1px solid $table-border-color;
      padding: $table-cell-padding;
      text-align: left;
      white-space: nowrap; // Keep text in one line
    }

    th {
      background-color: $table-header-background;
      font-weight: $table-header-font-weight;
      border-bottom: 2px solid $table-border-color; // Double border for headers
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: $table-row-hover-background;
    }
  }
  .select-container {
    margin-top: 20px;
    margin-bottom: 20px;

    label {
      margin-right: 10px;
      font-weight: bold;
    }

    select {
      padding: 5px;
      border: 1px solid $table-border-color;
      border-radius: 4px;
    }
  }

}
</style>