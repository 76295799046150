import { IFile } from "@/models/file";

export const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout> | null;

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            func.apply(this, args);
            timeoutId = null;
        }, delay);
    };
};

export const copyToClipboad = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);

    textarea.select();

    let success = false;
    try {
        const result = document.execCommand('copy');
        success = result ? true : false;
    } catch (err) {
        console.error('Error copying text to clipboard:', err);
    } finally {
        document.body.removeChild(textarea);
    }


    if (!success && navigator.clipboard) {
        return navigator.clipboard.writeText(text).then(() => true).catch(() => false);
    }

    return Promise.resolve(success);
}

export const downloadTxtFile = (text: string, filename: string) => {
    const blob = new Blob([text], { type: 'text/plain' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    try {
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
    
        document.body.appendChild(a);
        a.click();
    
    } finally {
        document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
};

export function getCookie(cname:string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function getImageUrl(file: IFile): string {
  if(file.url) {
    return file.url;
  } 
  if(file.file) {
    const _url = URL.createObjectURL(file.file)
    file.url = _url;
    return _url;
  }
  return "";
}

export function getFileFormat(file: File) {
  if(file.type.startsWith('image/')) {
    return "image";
  }
  if(file.type.startsWith('video/')) {
    return "video";
  }
  if(file.type.startsWith('audio/')) {
    return "sound";
  }
  const extension = file.name?.split('.')?.pop()?.toLowerCase();
  const wordExtensions = ['docx'];
  const excelExtensions = ['xls', 'xlsx', 'csv'];
  const powerpointExtensions = ['ppt', 'pptx'];
  if(extension && wordExtensions.includes(extension)) {
    return "docx";
  }
  if(extension && excelExtensions.includes(extension)) {
    return "xlsx";
  } 
  if(extension && powerpointExtensions.includes(extension)) {
    return "pptx";
  } 
  if(extension == 'pdf') {
    return "pdf";
  }
  if(extension == "txt") {
    return "txt";
  }
  return extension;
}

export function cleanAndExtractSentences() {
  function cleanText(text: string) {
      return text
          .replace(/[|]/g, '')
          .replace(/[^a-zA-Z0-9぀-ヿ一-龯。、！？.,!?'\s]/g, '')
          .replace(/\s+/g, ' ')
          .trim();
  }

  function splitIntoSentences(text: string) {
      return text
          .split(/(?<=[.,!?])\s+|(?<=[,])\s+(?=[a-z])|(?=[。！？])|\n+/)
          .map(sentence => cleanText(sentence))
          .filter(sentence => sentence.length > 0);
  }

  function findAllIndexes(sentence: string, text: string) {
    const indexes = [];
        
    const normalizedSentence = sentence.replace(/\s+/g, ' ').trim();
    const normalizedText = text.replace(/\s+/g, ' ').trim();

    const cleanSentence = normalizedSentence.replace(/[[],]/g, '');
    const cleanText = normalizedText.replace(/[[],]/g, '');

    let index = cleanText.indexOf(cleanSentence);
    while (index !== -1) {
        indexes.push(index);
        index = cleanText.indexOf(cleanSentence, index + 1);
    }

    if (indexes.length === 0) {
        return [];
    }
    return indexes;
  }


  function findSentenceIndexes(sentences: string[], text: string) {
      const allIndexes: any[] = [];
      const sentenceIndexes = sentences.map(sentence => {
          const indexes = findAllIndexes(sentence, text);
          allIndexes.push(...indexes);
          return { sentence, indexes: indexes.length > 0 ? indexes : [] };
      });

      allIndexes.sort((a, b) => a - b);

      const groupedIndexes = [];
      let currentGroup = [];
      const threshold = 100;

      for (let i = 0; i < allIndexes.length; i++) {
          if (currentGroup.length === 0 || allIndexes[i] - currentGroup[currentGroup.length - 1] <= threshold) {
              currentGroup.push(allIndexes[i]);
          } else {
              groupedIndexes.push([...currentGroup]);
              currentGroup = [allIndexes[i]];
          }
      }
      if (currentGroup.length > 0) {
          groupedIndexes.push([...currentGroup]);
      }

      const bestGroup = groupedIndexes.reduce((a, b) => (a.length > b.length ? a : b), []);

      const bestStartIndex = bestGroup.length > 0 ? bestGroup[0] : "Not Found";
      let bestEndIndex = bestGroup.length > 0 ? bestGroup[bestGroup.length - 1] : "Not Found";

      const lastSentence = sentences[sentences.length - 1];
      const lastSentenceIndexes = findAllIndexes(lastSentence, text);
      if (lastSentenceIndexes.length > 0) {
          const lastSentenceMaxIndex = Math.max(...lastSentenceIndexes);
          const lastSentenceLength = lastSentence.length;
          if (lastSentenceMaxIndex + lastSentenceLength > bestEndIndex) {
              bestEndIndex = lastSentenceMaxIndex + lastSentenceLength;
          }
      }

      const searchText = sentences.join(" ");
      const searchLength = searchText.length;

      if (typeof bestStartIndex === "number" && typeof bestEndIndex === "number") {
          if (bestEndIndex - bestStartIndex > searchLength * 1.5) {
              bestEndIndex = bestStartIndex + searchLength;
          }
      }

      return { sentenceIndexes, bestStartIndex, bestEndIndex };
  }

  return {
      splitIntoSentences,
      findSentenceIndexes
  };
}

export function renderHighlight({
    container,
    divs,
    highlightText,
    pageClass,
    page,
    customRegex,
  }: {
    container: Element;
    highlightText: string;
    pageClass?: string;
    page?: number;
    divs: Element[];
    customRegex?: RegExp;
  }) {
    
  if(!container || divs.length == 0 || !highlightText) return;
  divs = divs.filter((div:any) => div.innerText.trim() != '')
  container.querySelectorAll(".highlight").forEach((element:any) => {
    if (element.classList.contains("appended")) {
      element.replaceWith(...element.childNodes);
    } else {
      element.classList.remove("highlight", "selected", "begin", "end", "appended")
    }
  });
  const textContentItemsStr = divs.map((item: any) => item.innerText);
  let matches = [], matchesLength = [];
  if(!customRegex) {
    const regexStr = highlightText
        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        .replace(/\s+/g, '\\s*');
    const regex = new RegExp(regexStr, 'gi');
    ({matches, matchesLength} = calculateRegExpMatch(regex, textContentItemsStr.join("")));

    // ignore special symbol for search
    if(matches.length == 0) {
    const ignoreSpecialSymbolRegexStr = highlightText
          .replace(/([^\p{L}\d])\1*/gu, '[$1]*')
    const ignoreSpecialSymbolregex = new RegExp(ignoreSpecialSymbolRegexStr, 'gi');
    ({matches, matchesLength } = calculateRegExpMatch(ignoreSpecialSymbolregex, textContentItemsStr.join("")))
    }
  } else {
    ({matches, matchesLength} = calculateRegExpMatch(customRegex, textContentItemsStr.join("")));
  }

  // fuzzy search
  if(matches.length == 0) {
    const { splitIntoSentences, findSentenceIndexes } = cleanAndExtractSentences();
    const searchSentences = splitIntoSentences(highlightText);
    const { bestStartIndex, bestEndIndex } = findSentenceIndexes(searchSentences, textContentItemsStr.join(""));
    if(bestStartIndex == 0) {
      return false;
    }
    matches = [bestStartIndex];
    matchesLength = [bestEndIndex - bestStartIndex]
  }
  
  const result = convertMatches(matches, matchesLength, textContentItemsStr);
  renderMatches(result, textContentItemsStr, divs)
  if(container.querySelector(".highlight.selected")) {
    setTimeout(() => {
      container.querySelector(".highlight.selected")?.scrollIntoView({block: 'start'});
    }, 0);
  } else if(page && pageClass) {
    container.querySelectorAll(pageClass)?.[page! - 1]?.scrollIntoView();
  }

  function convertMatches(matches: number[], matchesLength: number[], textContentItemsStr: any, ) {
      // Early exit if there is nothing to convert.
      if (!matches) {
        return [];
      }
      let i = 0,
        iIndex = 0;
      const end = textContentItemsStr.length - 1;
      const result = [];
  
      for (let m = 0, mm = matches.length; m < mm; m++) {
        // Calculate the start position.
        let matchIdx = matches[m];
  
        // Loop over the divIdxs.
        while (i !== end && matchIdx >= iIndex + textContentItemsStr[i].length) {
          iIndex += textContentItemsStr[i].length;
          i++;
        }
  
        if (i === textContentItemsStr.length) {
          console.error("Could not find a matching mapping");
        }
  
        const match: any = {
          begin: {
            divIdx: i,
            offset: matchIdx - iIndex,
          },
        };
  
        // Calculate the end position.
        matchIdx += matchesLength[m];
  
        // Somewhat the same array as above, but use > instead of >= to get
        // the end position right.
        while (i !== end && matchIdx > iIndex + textContentItemsStr[i].length) {
          iIndex += textContentItemsStr[i].length;
          i++;
        }
  
        match.end = {
          divIdx: i,
          offset: matchIdx - iIndex,
        };
        result.push(match);
      }
      return result;
    }
  
  function renderMatches(matches: any[], textContentItemsStr: any, textDivs: any, hideHightLine = false) {
    const hightlineClass = hideHightLine ? "hidden-highlight" : "highlight";
    // Early exit if there is nothing to render.
    if (matches.length === 0) {
      return;
    }
    let prevEnd = null;
    const infinity = {
      divIdx: -1,
      offset: undefined,
    };
  
    function beginText(begin: any, className?: any) {
      const divIdx = begin.divIdx;
      textDivs[divIdx].textContent = "";
      return appendTextToDiv(divIdx, 0, begin.offset, className);
    }
  
    function appendTextToDiv(divIdx: any, fromOffset: any, toOffset: any, className?: any) {
      let div = textDivs[divIdx];
      if (div.nodeType === Node.TEXT_NODE) {
        const span = document.createElement("span");
        div.before(span);
        span.append(div);
        textDivs[divIdx] = span;
        div = span;
      }
      const content = textContentItemsStr[divIdx].substring(
        fromOffset,
        toOffset
      );
      const node = document.createTextNode(content);
      if (className) {
        const span = document.createElement("span");
        span.className = `${className} appended`;
        span.append(node);
        div.append(span);
        return className.includes("selected") ? span.offsetLeft : 0;
      }
      div.append(node);
      return 0;
    }
  
    const i0 = 0, i1 = matches.length;
  
    let lastDivIdx = -1;
    let lastOffset = -1;
    for (let i = i0; i < i1; i++) {
      const match = matches[i];
      const begin = match.begin;
      if (begin.divIdx === lastDivIdx && begin.offset === lastOffset) {
        // It's possible to be in this situation if we searched for a 'f' and we
        // have a ligature 'ff' in the text. The 'ff' has to be highlighted two
        // times.
        continue;
      }
      lastDivIdx = begin.divIdx;
      lastOffset = begin.offset;
  
      const end = match.end;
      let selectedLeft = 0;
  
      const isSelected = i == 0;
      const highlightSuffix = isSelected ? " selected" : "";
  
      // Match inside new div.
      if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
        // If there was a previous div, then add the text at the end.
        if (prevEnd !== null) {
          appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
        }
        // Clear the divs and set the content until the starting point.
        beginText(begin);
      } else {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset);
      }
  
      if (begin.divIdx === end.divIdx) {
        selectedLeft = appendTextToDiv(
          begin.divIdx,
          begin.offset,
          end.offset,
          hightlineClass + highlightSuffix
        );
      } else {
        selectedLeft = appendTextToDiv(
          begin.divIdx,
          begin.offset,
          infinity.offset,
          hightlineClass + " begin" + highlightSuffix
        );
        for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
          textDivs[n0].className = hightlineClass +" middle" + highlightSuffix;
        }
        beginText(end, hightlineClass +" end");
      }
      prevEnd = end;
  
    }
  
    if (prevEnd) {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
    }
  }
  
  function calculateRegExpMatch(query: any, pageContent: any) {
      const matches = [], matchesLength = [];
      if (!query) {
        return {matches: [],
          matchesLength: []};
      }
      let match;
      while ((match = query.exec(pageContent)) !== null) {
        if (match.index === query.lastIndex) {
            query.lastIndex++;
        }
  
        if (match[0].length) {
          matches.push(match.index,);
          matchesLength.push(match[0].length);
        }
      }
      return {
        matches: matches,
        matchesLength: matchesLength
      }
    }
}